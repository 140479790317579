// #add-exercise => page create-exercise.pug
// #dublicate-exercise => page dublicate-exercise.pug
#add-exercise,#dublicate-exercise {
  .placeholder-up label {
    top:6px;
  }
  .placeholder-up input[type="text"]:focus + label {
    top:-15px;
  }
  label {
    font-weight:bold;
    font-size:16px;
  }
  .quiz-select::after {
    content: "\f0dd";
    background-color:transparent;
    color:#888;
    font-size: 20px;
    top: 5px;
  }
  .quiz-select select {
    font-size:16px;
    color:$flatBlue;
  }
  h5 {
    font-size:15px;
    i {
      color:$flatOrange;
      margin:0 5px;
    }
  }
  input[type='number'] {
    max-width:60px;
    border-radius:5px;
  }
  .btns {
    width: 100%;
    text-align: end;
    padding: 10px 0;
    &.border-top {
      border-top:1px solid #00b0f0;
    }
    .btn {
      padding:4px 15px;
    }
    .cancel {
      color:$flatBlue;
      font-weight:bold;
      border:1px solid #00b0f0;
    }
  }
}

// #statistics => page exercise-statistics.pug
#statistics {
  .modal-dialog {
    max-width:500px;
  }
  .modal-header {
    background-color:#fff;
  }
  .modal-body ul {
    background-color:#fff;
    padding:5px 10px;
    font-size:20px;
    li {
      margin-bottom:5px;
      color:#888;
      &.orange {
        color:#f60;
      }
      span {
        color:$flatBlue;
        margin-right:10px;
        &:nth-of-type(2) {
          margin-left:20px;
        }
      }
    }
  }
}