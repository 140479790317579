.quiz-select {
  position: relative;
  background-color: #f9f9f9;
  z-index: 1;
  height: 34px;
  &:after {
    font-family: "Font Awesome 5 Free";
    content: "\f078";
    display: block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 12px;
    background-color: $altColor;
    font-weight: 600;
  }
  select {
    width: 100%;
    height: 100%;
    padding: 0 10px 0 15px;
    position: relative;
    z-index: 2;
    background: transparent !important;
    line-height: 1.8;
    appearance: none;
    &:focus {
      outline: none;
    }
  }
  select::-ms-expand {
    display: none;
  }
}
