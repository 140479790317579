.intl-tel-input {
  width: calc(100% - 46px);
  .selected-flag {
    z-index: 4;
  }
  .country-list {
    z-index: 5;
  }
  .flag-container {
    z-index: 9999;
  }
}
.phone-number {
  border-radius: 4px 0 0 4px !important;
}
