.send-message {
  .modal-header {
    border-bottom: none;
    position: relative;
    .close {
      position: absolute;
      right: 15px;
    }
    .user-info {
      img {
        width: 100px;
        height: 100px;
      }
      .modal-title {
        font-weight: bold;
        font-size: 20px;
        color: #666;
        margin: 10px 0 5px;
        & ~ p {
          font-weight: bold;
          color: #E64946;
          font-size: 16px;
        }
      }
    }
  }
  textarea {
    height: 220px;
    resize: none;
  }
}
