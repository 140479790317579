.help-and-faq {
  .modal-content {
    background-color: #fafafa;
  }
  .form-control {
    &:focus {
      box-shadow: none;
    }
  }
  .first-line {
    .quiz-select {
      margin-bottom: 10px;
    }
    @media (min-width: 768px) {
      overflow: hidden;
      .quiz-select {
        float: left;
        width: 28%;
        margin-right: 2%;
      }
      .subject-input {
        float: left;
        width: 70%;
      }
    }
  }
  textarea {
    height: 120px !important;
  }
}
