.create-question {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;
  overflow: auto;
  padding: 0 10px;

  @include responsive-mobile {
    padding: 0 5px;
  }

  .minimal-width-section {
    @include responsive-mobile {
      margin-top: 0;
    }
  }

  header {
    display: flex;
    align-items: center;
    padding: 30px 0 10px;
    border-bottom: 1px solid $primary-color;
    background-color: white;
    z-index: 10;
    position: sticky;
    top: 0;

    @include responsive-mobile {
      flex-wrap: wrap;
    }

    .left,
    .right {
      display: flex;
      align-items: center;
      @include responsive-mobile {
        margin: 5px 0;
        justify-content: space-between;
        width: 100%;
      }
    }

    .left {
      margin-right: auto;
    }

    .right {
      @include responsive-mobile {
        margin-top: 10px;
      }
    }

    .question-number {
      margin-right: auto;
      margin-bottom: 0;
    }

    .form-group {
      margin: 0 5px;

      .quiz-select {
        width: 180px;

        @include responsive-mobile {
          margin-right: 0;
        }

        select {
          padding: 0 5px;
          @include responsive-mobile {
            font-size: 14px;
          }
        }
      }

      .custom-checkbox {
        .form-check {
          @include responsive-mobile {
            font-size: 14px;

            label {
              &::before {
                width: 18px;
                height: 18px;
              }
              &::after {
                bottom: 0;
              }
            }
          }
        }
      }
    }

    #close-create-question {
      border-radius: 50px;

      i {
        display: none;
      }

      @include responsive-tablet {
        padding: 0;
        font-size: 15px;
        border-radius: 50%;
        width: 30px;
        height: 30px;

        i {
          display: block;
        }
        span {
          display: none;
        }
      }

      @include responsive-mobile {
        position: absolute;
        right: 0;
        z-index: 1;
        border: 0;
        bottom: 70px;
      }
    }

    .quiz-model-btn-success {
      min-width: 150px;
    }
  }

  main {
    margin: 10px 0;

    .text-passage,
    .text-passage-multi-dropdown {
      position: relative;

      .text-passage-editor {
        height: 200px;

        @include responsive-mobile {
          min-height: 180px;
        }

        & > div {
          line-height: 1.8;
          font-size: 1rem;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
            sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        }
      }

      .ql-toolbar {
        position: -webkit-sticky;
        position: sticky;
        top: 77px;
        z-index: 9;
        @include responsive-mobile {
          top: 135px;
        }
      }
    }

    .text-passage-multi-dropdown {
      display: none;

      .ql-toolbar {
        .ql-formats {
          .ql-multiDropDown {
            img {
              height: 100%;
              margin-top: -7px;
            }
          }

          @include responsive-mobile {
            margin-right: 7px;
          }
        }
      }

      .text-passage-editor {
        .dropdown-img {
          cursor: pointer;
        }
      }
    }

    .editing-wizard {
      display: none;
      text-align: center;
      padding: 20px 0;
      button {
        margin: 10px 0;
      }
      .question-details {
        text-align: left;
        padding: 10px;
        border: 1px solid #ddd;
        max-height: 300px;
        overflow-x: auto;
        .blank {
          display: inline-block;
          text-align: center;
          margin: 0 5px;
          border-radius: 3px;
          width: 50px;
          background-color: #ffff99;
          border: 1px solid #ddd;
        }
      }
    }

    .questions-settings {
      position: relative;

      header {
        padding-top: 0;
        margin-top: 20px;
        border-bottom: none;
        position: initial;

        @include responsive-mobile {
          flex-wrap: nowrap;
        }

        .left,
        .right {
          width: auto;
          @include responsive-mobile {
            justify-content: normal;
          }
        }
        .right {
          margin-top: 0;
        }

        .attachments {
          font-size: 18px;
          position: relative;
          margin-left: 10px;
          cursor: pointer;

          @include responsive-mobile {
            span {
              display: none;
            }
          }

          i {
            color: #0097e6;
            padding: 5px;
            border: 1px solid #ddd;
            border-radius: 5px;
            margin: 0 5px;
          }

          &::before {
            content: "3";
            position: absolute;
            background-color: #777;
            color: white;
            width: 20px;
            height: 20px;
            font-size: 15px;
            text-align: center;
            line-height: 20px;
            border-radius: 50px;
            top: -5px;
            left: -5px;
          }
        }

        .form-group .quiz-select {
          margin-right: 0;
          width: 140px;
        }

        input {
          border: 1px solid #ddd;
          border-radius: 5px;
          width: 50px;
          padding: 4px 0;
          color: $primary-color;
          text-align: center;

          &.error {
            background-color: #fff5f5 !important;
            border-color: #ff0000 !important;
          }
        }
      }

      .instructions {
        @include responsive-mobile {
          font-size: 14px;
        }
      }

      .error-selected-message {
        background-color: #fff5f5;
        border: 1px solid #ff0000;
        padding: 2px 5px;
        border-radius: 5px;
        position: absolute;
        top: 40px;
        right: 5px;
        display: none;
      }

      .question-type {
        display: none;
        &.selected {
          display: block;
        }
      }

      .question-choices {
        list-style: none;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 5px 0;
          padding: 5px 0;
          position: relative;

          .choice-drag {
            font-size: 20px;
            color: #bfbfbc;
            position: absolute;
            cursor: move;
          }

          .question-status,
          .choice-number {
            position: absolute;
            left: 32px;
            font-size: 18px;
            padding: 5px 10px;
            cursor: pointer;
            color: #00cc99;

            @include responsive-mobile {
              left: 30px;
            }
          }
          .custom-checkbox,
          .custom-radio {
            position: absolute;
            left: 40px;
            top: 50%;
            transform: translateY(-50%);

            @include responsive-mobile {
              left: 30px;
            }

            input[type="checkbox"]:checked + label::before {
              background-color: #00cc99;
              border-color: #00cc99;
            }
            input[type="radio"]:checked + label::after {
              line-height: 14px;
            }
            input[type="radio"]:checked + label::after {
              bottom: 3px;
            }
          }

          input,
          textarea {
            border: 1px solid #ddd;
            background-color: #fff;
            border-radius: 5px;
            flex-grow: 1;
            margin-left: 30px;
            margin-right: 10px;
            padding: 8px 5px 8px 40px;
            min-height: 42px;
            height: auto;
            resize: none;

            @include responsive-mobile {
              width: 75%;
              margin-left: 20px;
              padding: 8px 5px 8px 35px;
            }

            &[type="number"] {
              padding: 8px 1px;
              width: 48px;
              flex-grow: 0;
              margin-left: 0;
              text-align: center;
              display: none;

              &.error {
                background-color: #fff5f5 !important;
                border-color: #ff0000 !important;
              }
            }
          }

          i {
            color: #bfbfbc;
            cursor: pointer;
            &:first-of-type {
              padding: 6px 3px;
            }
            &:last-of-type {
              padding: 6px 0;
            }
          }

          &.selected {
            textarea,
            input[type="text"] {
              background-color: #f9ffff;
              border-color: #0c9;
            }
          }

          &.error {
            textarea,
            input[type="text"] {
              background-color: #fff5f5 !important;
              border-color: #ff0000 !important;
            }
          }
        }
      }

      .multi-choices .question-choices li > label {
        position: absolute;
        left: 30px;
        width: 35px;
        height: 40px;
        margin: 0;
        cursor: pointer;
      }

      .multi-true-false .question-choices li {
        textarea {
          padding-left: 45px;
        }

        .question-status {
          left: 30px;
          font-size: 30px;

          &[data-status="wrong"] {
            font-size: 35px;
          }

          @include responsive-mobile {
            left: 20px;
          }
        }
      }

      .true-false {
        .question-choices li .custom-radio {
          left: 20px;
        }
        .nice-select {
          font-family: "Font Awesome 5 Free", "Segoe UI";
          padding: 0 5px;
          width: 130px;
          background-color: #f9f9f9;
          height: 34px;
          line-height: 32px;

          .list {
            width: 130px;
            .option {
              padding: 0 10px;
              min-height: 20px;
              line-height: 32px;
              color: #333;

              &:hover {
                background-color: #0097e6;
                color: #fff;
              }
              &.selected,
              &.focus {
                background-color: #0097e6;
                color: #fff;
                font-weight: normal;
              }
            }
            &:hover {
              .option:not(:hover) {
                color: #333;
              }
            }
          }

          &:focus {
            outline: none;
            border-color: #80bdff;
            background-color: transparent;
            color: #333;
          }

          &::after {
            border-color: #fff;
          }
          &::before {
            font-family: "Font Awesome 5 Free";
            content: "";
            display: block;
            width: 18px;
            height: 18px;
            line-height: 18px;
            text-align: center;
            color: #fff;
            position: absolute;
            right: 5px;
            top: 7px;
            font-size: 12px;
            background-color: #0097e6;
            font-weight: 600;
          }
        }

        .true-false-type {
          &::after {
            content: none;
          }
        }
      }

      .true-false .question-choices,
      .multi-dropdown-choices {
        list-style: none;

        li {
          input {
            margin-left: 10px;
          }

          &.correct::after,
          &.wrong::after,
          &.like::after,
          &.dislike::after {
            content: "\2713";
            position: absolute;
            left: 55px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 20px;
          }
          &.wrong::after {
            content: "\00D7";
          }
          &.like::after {
            content: "\f164";
            font-family: "Font Awesome 5 Free";
          }
          &.dislike::after {
            content: "\f165";
            font-family: "Font Awesome 5 Free";
          }
        }
      }

      .ordering {
        .question-choices li .choice-number {
          font-weight: bold;
          font-size: 16px;
          left: 30px;

          @include responsive-mobile {
            left: 20px;
          }
        }
      }

      .multi-drop-down,
      .fill-in-the-blanks {
        header {
          .partial-points {
            display: none;
          }
        }

        .multi-dropdown-choices {
          display: none;

          .error-selected-message {
            right: 60px;
            top: 42px;
          }

          .question-choices li {
            .custom-checkbox {
              left: 20px;
            }

            textarea {
              margin-left: 10px;
            }
          }

          &.selected {
            display: block;
            & ~ footer {
              display: flex;
            }
          }
        }

        footer {
          display: none;
        }
      }

      .fill-in-the-blanks {
        header {
          display: none;
        }
      }

      .file-upload {
        display: none;

        .files-settings {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

          .option {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 8px 10px 8px 0;
            height: 40px;
            border: 1px solid #dddddd;
            border-radius: 5px;
            padding: 3px;

            &:not(:last-child) {
              margin-right: 10px;
            }
            input {
              border: 1px solid #ddd;
              border-radius: 5px;
              width: 50px;
              padding: 4px 0;
              color: #0097e6;
              text-align: center;

              &.error {
                background-color: #fff5f5 !important;
                border-color: #ff0000 !important;
              }
            }

            p {
              margin: 0 20px 0 0;
            }

            .switch {
              margin-right: 5px;
              .slider {
                margin: 0;

                &::before {
                  bottom: 0;
                }
              }
            }
          }
        }

        .specific-files {
          display: none;
          margin-top: 20px;
          .content {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            padding: 0 50px;

            .type {
              display: flex;
              align-items: center;
              flex-basis: 25%;
              flex-shrink: 0;
              margin: 5px 0;

              @include responsive-mobile {
                flex-basis: 50%;
              }

              label {
                margin: 0 2px;
                cursor: pointer;
              }
            }
          }
        }
      }

      .short-answer,
      .essay-question {
        .counter {
          display: flex;
          align-items: center;
          p {
            margin: 0 10px 0 auto;
          }
          label {
            margin: 0;
            &::before {
              bottom: 0;
            }
          }
        }
      }

      .ui-sortable-helper {
        background-color: #d3f7ef;
      }

      footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        @include responsive-mobile {
          padding: 0 20px 20px;
        }

        .form-group {
          margin-bottom: 0;
          select {
            padding: 0 5px;
            min-width: 170px;
            font-family: "Font Awesome 5 Free", "Segoe UI";

            @include responsive-mobile {
              font-size: 14px;
              min-width: 150px;
            }
          }
        }

        button {
          @include responsive-mobile {
            padding: 0;
            width: 30px;
            height: 30px;
            text-align: center;
            span {
              display: none;
            }
            i {
              margin-right: 0 !important;
            }
          }
        }

        div {
          display: flex;
          align-items: center;

          @include responsive-mobile {
            font-size: 14px;
          }

          p {
            margin: 0 10px;
          }

          label {
            margin: 0;

            &::before {
              bottom: 0;
            }
          }
        }
      }
    }
  }
}
