@mixin ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin settingHeaderStyles {
  background-color: #002060;
  height: 50px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  h3 {
    font-size: 20px;
    margin: 0;
    @include ellipsis;

    @include responsive-mobile {
      font-size: 17px;
    }
  }
  .setting-close-icon {
    position: initial;
    background-color: #ddd;
    color: #002060;
    border: 0;
    border-radius: 50%;
    font-size: 15px;
  }
}

@mixin responsive-mobile {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin responsive-tablet {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin responsive-desktop {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin only-large-screen {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin tabSwitcherResponsive {
  @include responsive-mobile {
    position: relative;
    background-color: #f1f1f1;
    border-top: 1px solid #ddd;
    overflow: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    li {
      margin-left: 0;
      min-width: 160px;
      transition: margin-left 0.2s ease;
    }
  }
}

@mixin inputStyle {
  font-size: 17px;
  padding: 3px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin: 7px 0;
  width: 100%;

  &:focus {
    outline: none;
    border-color: $primary-color;
  }
}

@mixin textPassageStyle {
  line-height: 1.8;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@mixin backBtnStyle {
  position: absolute;
  left: 5px;
  bottom: 15px;
  color: #ff8c00;
  font-size: 18px;
  i {
    margin: 0 5px;
  }
}
