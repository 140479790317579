$dark: #333;
$primary-color: #0097e6;
$orange: #ff8c00;
$purple: #9C27B0;
$dark-blue: #40739E;
$green: #06bf2c;
$red: red;
$yellow: #ff8c00;
$gray: #777;
$selectedColor: #DFFBBD;
$lightGrayColor: #f1f1f1;
$sidebarColor: #002060;
// FF5050

$newBlue: #0097e6;


$maxMobile: "(max-width: 767px)";
$maxSmall: "(max-width: 991px)";
$minSmall: "(min-width: 768px)";
$minMedium: "(min-width: 992px)";
$minLarge: "(min-width: 1200px)";

$mainColor: #E64946;
$altColor: #0097e6;

$flatRed: #e84118;
$flatBlue: #0097e6;
$flatGreen: #44bd32;
$flatOrange: #e67e22;
