.quiz-checkbox {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  input[type="checkbox"] {
    appearance: none;
    display: none;
    &:checked ~ label:before {
      content: "";
      background-color: $flatBlue;
      border-color: $flatBlue;
    }
    &:checked ~ label:after {
      font-family: "Font Awesome 5 Free";
      content: "\f00c";
      position: absolute;
      color: #fff;
      left: 4px;
      bottom: 0px;
      font-size: 12px;
      font-weight: 600;
    }
  }
  label {
    margin: 0;
    cursor: pointer;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: 2px solid #adadad;
      border-radius: 3px;
    }
  }
}
