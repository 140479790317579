.add-person-modal {
  .form-group {
    text-align: center;
    padding: 30px 0;
    background-color: #f0f0f0;
  }
  .form-control {
    display: inline-block;
    width: 54px;
    font-size: 24px;
    font-weight: bold;
    color: $flatBlue;
    text-align: center;
    text-transform: uppercase;
    &:focus {
      box-shadow: none;
      color: $flatBlue;
    }
    @include responsive-tablet {
      width: 35px;
      padding: 0;
      height: 35px;
      font-size: 20px;
    }
  }
  .input-separator {
    font-size: 30px;
    font-weight: bold;
    margin: 0 10px;
    color: #a6a6a6;
  }
  .big-width {
    min-width: 100px;
  }
}
