.w-h-100 {
  width: 100px;
  height: 100px;
}
.w-90 {
  width: 90px;
}
.align-center {
  display: flex;
  align-items: center;
}
.text-center-xs {
  @media (max-width: 575px) {
    text-align: center;
  }
}

// Pages
.the-page-title {
  margin: 20px 0 30px;
  font-size: 30px;
  @include responsive-tablet {
    text-align: center;
    margin: 20px 0;
  }
}

// Form
.control-label {
  font-weight: bold;
  text-align: right;
  padding-top: 4px;
  color: #555;
  @media (max-width: 575px) {
    text-align: left;
  }
}

// Helpers
.no-select {
  user-select: none;
}
.flex {
  display: flex;
  .btn {
    flex: 1;
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}

// Custom Grid
@media (max-width: 575px) {
  .xs-6 {
    flex-basis: 50% !important;
  }
  .xs-12 {
    flex-basis: 100% !important;
  }
}

// Resets
.pointer-none {
  pointer-events: none;
  opacity: 0.6;
}
