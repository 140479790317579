.quiz-radio {
  input[type="radio"] {
    appearance: none;
    display: none;
    &:checked ~ label:after {
      content: '';
      position: absolute;
      color: #FFF;
      left: 5px;
      top: 5px;
      width: 12px;
      height: 12px;
      background-color: $flatBlue;
      border-radius: 50%;
    }
  }
  label {
    position: relative;
    padding-left: 24px;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: #FFF;
      border: 2px solid #ADADAD;
    }
  }
}
