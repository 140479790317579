.textarea-counter {
  position: relative;
  .count-chars {
    position: absolute;
    bottom: 1px;
    right: 1px;
    padding: 5px 10px;
    background-color: #EEE;
    font-weight: bold;
    color: #888;
    border-radius: 0 0 4px 0;
  }
  .reach-zero {
    color: $flatRed;
  }
  textarea {
    background-color: #fefefe;
    font-size: 14px;
  }
}
